import { MaybeNull } from "types";

import { getResData, httpClient } from "utils";

import { CollectorDTO } from "./collectors";
import { UserDTO } from "./users";

export const SITES_API_PATHS = {
  SITES: "/sites",
  SITE: (siteId: number) => `/sites/${siteId}`,
  UPLOAD_SITE_MANAGERS: "/upload",
  UPLOAD_SCOPE_3: "/upload",
  DELETE_COLLECTOR: (collectorId: number) => `/collector/${collectorId}`,
  SCOPE_UPLOAD_SITE_MANAGERS: "/scope3upload",
  RESET_LEARNING: "/supplies-learnings",
  DELETE_SITE: (siteId: number) => `/sites/disable/${siteId}`,
  ENABLE_SITE: (siteId: number) => `/sites/enable/${siteId}`,
  ESTIMATES: "/estimates",
  DEACTIVATE_ESTIMATES: (estimateId: number) =>
    `/estimates/deactivate/${estimateId}`,
  DEACTIVATEDLIST_ESTIMATES: (siteId: number) =>
    `estimates/de-activated/${siteId}`,
    UPLOAD_INTENSITYNULL: "",
    GETREGION: (data:any) => `/sites/getRegion/${data.country}/${data.zipCode}`,  
  EXPORT_SITES_DATA:"/sites/export",
  RECS_DETAILS:"/sites/export/recs"
};
export const INTRAPOLATION_API_PATHS = {
 
  DATAFILL: "/interextrapolate",
  DATAVIEWASOFDATE: "/ViewAsOfDate",
  SAVEDATA: "/inter",

};
export const INTENSITY_API_PATHS = {
  INTENSITY: "/intensitydata/company",
  INTENSITYS: (companyId: number) => `/intensitydata/company/${companyId}`,
  UPLOAD_INTENSITY: "/upload",
  DELETE_COMPANYLEVEL: (companyId: number) => `/intensitydata/company/${companyId}`,


};
export const INTENSITYSITE_API_PATHS = {
  INTENSITYSITE: "intensitydata/site",
  INTENSITYSITES: (intensitysiteId: number) => `/intensitydata/site/${intensitysiteId}`,
  UPLOAD_INTENSITY: "/upload",
  DELETE_SITELEVEL: (siteId: number) => `/intensitydata/site/${siteId}`,

};

export interface SiteDTO {
  address: MaybeNull<string>;
  city: MaybeNull<string>;
  country: MaybeNull<string>;
  createDate: MaybeNull<string>;
  id: number;
  name: MaybeNull<string>;
  region: MaybeNull<string>;
  type: MaybeNull<string>;
  updateDate: MaybeNull<number>;
  users: UserDTO[];
  zipCode: MaybeNull<string>;
  countryRegion: MaybeNull<string>;
  enabled: boolean;
}
export interface IntensityDTO {
  companyName: MaybeNull<string>;
  siteName: MaybeNull<string>;
  intensityCategory: MaybeNull<string>;
  value: MaybeNull<number>;
  id: number;
  unit: MaybeNull<string>;
  uploadDate: MaybeNull<string>;
  startDate: MaybeNull<string>;
  endDate: MaybeNull<number>;
}

export interface EstimateDTO {
  id: number;
  siteId: number;
  utilityTypeId: number;
  estimateName: string;
  estimateType: string;
  days: number;
  estimateHeadCount: number;
  estimateArea?: number;
  estimateAreaUnit?: string;
  estimateUsage: number;
  estimateUsageUnit: string;
  estimateStartDate: string;
  estimateEndDate: string;
  frequency: string;
  notes?: string;
}
export interface DataBillDTO {
  id: number;
  selectOne: string;
  fromDate: string;
  toDate:  string;
  selectOnetype:  string;
}
export interface SiteByIdDTO extends SiteDTO {
  collectors: CollectorDTO[];
  status: MaybeNull<string>;
  estimates?: EstimateDTO[];
}

type UpdateSite = Omit<SiteDTO, "createDate" | "updateDate" | "users"> & {
  dataProviders: number[];
};

type UpdateIntensity = Omit<IntensityDTO, "createDate" | "updateDate" | "users"> & {
  dataProviders: number[];
};

type CreateSiteDTO = Omit<
  SiteDTO,
  "createDate" | "updateDate" | "users" | "id"
>;

type CreateEstimateDTO = Omit<
  EstimateDTO,
  "createDate" | "updateDate" | "users" | "id"
>;

type UpdateEstimate = Omit<EstimateDTO, "createDate" | "updateDate" | "users">;

export const getSites = (): Promise<SiteDTO[]> =>
  httpClient.get<SiteDTO[]>(SITES_API_PATHS.SITES).then(getResData);

export const getIntensity = (): Promise<IntensityDTO[]> =>
  httpClient.get<IntensityDTO[]>(INTENSITY_API_PATHS.INTENSITY).then(getResData);

  export const getIntensitysite = (): Promise<IntensityDTO[]> =>
  httpClient.get<IntensityDTO[]>(INTENSITYSITE_API_PATHS.INTENSITYSITE).then(getResData);

export const createSite = (body: CreateSiteDTO) =>
  httpClient.post(SITES_API_PATHS.SITES, body);

export const getSiteById = (id: number): Promise<SiteByIdDTO> =>
  httpClient.get<SiteByIdDTO>(SITES_API_PATHS.SITE(id)).then(getResData);

export const updateSite = (data: UpdateSite) =>
  httpClient.put(SITES_API_PATHS.SITES, data);

  export const updateIntensity = (data: UpdateIntensity) => 
  httpClient.put(INTENSITY_API_PATHS.INTENSITY, data);

  export const updateIntensitysite = (data: UpdateIntensity) => 
  httpClient.put(INTENSITYSITE_API_PATHS.INTENSITYSITE, data);

export const deleteSiteById = (id: number) =>
  httpClient.delete(SITES_API_PATHS.SITE(id));

export const uploadSites = ({
  file,
  isScope3 = false,
  overrideFlag = false
}: {
  file: FormData;
  isScope3?: Boolean;
  overrideFlag?: any;
}) =>
  httpClient.post(
    isScope3
      ? SITES_API_PATHS.SCOPE_UPLOAD_SITE_MANAGERS
      : SITES_API_PATHS.UPLOAD_SITE_MANAGERS,
    file,
    overrideFlag,
  );

  export const uploadintensity = ({
    file,
    isScope3 = false,
  }: {
    file: FormData;
    isScope3?: Boolean;
  }) =>
    httpClient.post(
      isScope3 
        ? INTENSITY_API_PATHS.UPLOAD_INTENSITY
        : INTENSITYSITE_API_PATHS.UPLOAD_INTENSITY,
      file,
    );
export const uploadScope3 = (file: FormData) =>
  httpClient.post(SITES_API_PATHS.UPLOAD_SCOPE_3, file);

export const deleteCollectorById = (id: number) =>
  httpClient.delete(SITES_API_PATHS.DELETE_COLLECTOR(id));

export const deActivateEstimateById = (id: number) =>
  httpClient.put(SITES_API_PATHS.DEACTIVATE_ESTIMATES(id));

export const deleteSite = (id: number) =>
  httpClient.put(SITES_API_PATHS.DELETE_SITE(id));

export const enableSite = (id: number) =>
  httpClient.put(SITES_API_PATHS.ENABLE_SITE(id));
  
export const deleteCompanyLevel = (id: number) =>
httpClient.delete(INTENSITY_API_PATHS.DELETE_COMPANYLEVEL(id));

export const deleteSiteLevel = (id: number) =>
httpClient.delete(INTENSITYSITE_API_PATHS.DELETE_SITELEVEL(id));

export const deleteResetLearning = () =>
  httpClient.delete(SITES_API_PATHS.RESET_LEARNING);

export const deactivatedSites = () =>
  httpClient.delete(SITES_API_PATHS.RESET_LEARNING);

export const createEstimates = (body: CreateEstimateDTO) =>
  httpClient.post(SITES_API_PATHS.ESTIMATES, body);

export const updateEstimate = (data: UpdateEstimate) =>
  httpClient.put(SITES_API_PATHS.ESTIMATES, data);

export const getDeActivatedEstimatesBySiteId = (
  siteId: number,
): Promise<EstimateDTO[]> =>
  httpClient
    .get<EstimateDTO[]>(SITES_API_PATHS.DEACTIVATEDLIST_ESTIMATES(siteId))
    .then(getResData);

    export const getRegion = (data: any) =>
  httpClient.get(SITES_API_PATHS.GETREGION(data)).then(getResData);


  export const createDataBill = (body: any) =>
  httpClient.post(INTRAPOLATION_API_PATHS.DATAFILL, body);
  
  export const createSaveData = (body: any) =>
  httpClient.post(INTRAPOLATION_API_PATHS.SAVEDATA, body);

  export const createViewAsOfDate = (body: any) =>
  httpClient.post(INTRAPOLATION_API_PATHS.DATAVIEWASOFDATE, body);

  export const exportSites = (): Promise<any[]> =>
    httpClient.get<any[]>(SITES_API_PATHS.EXPORT_SITES_DATA).then(getResData);

  export const exportRecsDetailBySite = (): Promise<any[]> => 
    httpClient.get<any[]>(SITES_API_PATHS.RECS_DETAILS).then(getResData);
  