import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useState } from "react";

import { SiteByIdDTO, UserDTO, updateSite } from "api";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  users: UserDTO[];
  site: SiteByIdDTO;
}

export const UserModal: FC<UserModalProps> = ({
  isOpen,
  onClose,
  users,
  site,
}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutateAsync: updateSiteRequest } = useMutation(updateSite);

  const handleAddProvider = async () => {
    const selectedDataProviderId = values["Data Provider"]?.id;
    const existingUserIds = site.users.map((user) => user.id);
    const updatedDataProviders = [...existingUserIds, selectedDataProviderId];

    await updateSiteRequest(
      {
        ...site,
        dataProviders: updatedDataProviders,
      },
      {
        onError: () => {
          notify.error(
            "Some error has occurred while adding the data provider!",
          );
        },
        onSuccess: () => {
          notify.success("Data provider successfully added!");
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          setAvailableDataProviders((prevDataProviders) =>
            prevDataProviders.filter(
              (provider: any) => provider.providerId !== selectedDataProviderId,
            ),
          );
        },
      },
    );
    setValues({});
    onClose();
  };

  const [availableDataProviders, setAvailableDataProviders] = useState(() => {
    if (Array.isArray(users)) {
      return users.map((u) => ({
        ...u,
        fullName: `${u.firstName || ""} ${u.lastName || ""}`,
        providerId: u.id,
      }));
    }
    return [];
  });

  const [values, setValues] = useState<any>({});

  const handleClose = () => {
    setValues({});
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add Data Provider</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormControl sx={{ minWidth: 500 }}>
            <Select
              value={values["Data Provider"]?.providerId || ""}
              name="Data Provider"
              onChange={(e) => {
                const selectedUserId = e.target.value;
                const selectedUser = availableDataProviders.find(
                  (user: any) => user.providerId === selectedUserId,
                );
                setValues({
                  ...values,
                  [e.target.name]: selectedUser,
                });
              }}
              label="Data Provider"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableDataProviders.map((option: any) => (
                <MenuItem key={option.providerId} value={option.providerId}>
                  {option.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAddProvider} variant="contained" color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
