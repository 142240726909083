import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  SiteByIdDTO,
  UnitDescription,
  createCollector,
  getSiteById,
  updateCollector,
} from "api";

import {
  COLORS,
  InputFieldWrapper,
  Switch,
  TYPOGRAPHY_COMMON_STYLES,
} from "components";

import { useGetAllowedValues, useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { checkNullValue, getDateFormatset } from "utils";

interface CollectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues?: any;
  columns?: any;
  site?: SiteByIdDTO;
  editCollector?: any;
  collectorData?: any;
  isCollectorEditNotes?: boolean;
  onCollectorDataUpdate?: (siteData: SiteByIdDTO) => void;
}

export const CollectorModal: FC<CollectorModalProps> = ({
  isOpen,
  onClose,
  columns,
  allowedValues,
  site,
  editCollector,
  collectorData,
  isCollectorEditNotes,
  onCollectorDataUpdate,
}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const initialFormValues =
    columns &&
    columns.reduce((acc: any, column: any) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {} as any);

  const { control } = useForm<any>({
    mode: "onChange",
    defaultValues: initialFormValues,
  });

  useEffect(() => {
    if (editCollector) {
      const updatedValues = columns.reduce((acc: any, column: any) => {
        acc[column.accessorKey ?? ""] = editCollector[column.accessorKey] || "";
        return acc;
      }, {} as any);
      setValues(updatedValues);
    } else {
      setValues(initialFormValues);
    }
    // eslint-disable-next-line
  }, [columns, editCollector]);

  const [values, setValues] = useState<any>(initialFormValues);
  const [notesError, setNotesError] = useState(false);
  const [notes, setNotes] = useState<any>(collectorData?.notes);

  const [availableUnitDescriptions, setAvailableUnitDescriptions] = useState<
    UnitDescription[]
  >([]);

  const [isSpendBasedEstimation, setIsSpendBasedEstimation] = useState(false);

  useEffect(() => {
    if (editCollector) {
      setIsSpendBasedEstimation(editCollector.spendBased);
    }
  }, [editCollector?.spendBased, editCollector]);

  const handleClose = () => {
    setValues(initialFormValues);
    setIsSpendBasedEstimation(false);
    onClose();
  };

  const { mutateAsync: createCollectorRequest } = useMutation(createCollector);

  const { mutateAsync: updateCollectorRequest } = useMutation(updateCollector);

  useEffect(() => {
    setNotes(collectorData?.notes);
  }, [collectorData]);

  const {
    uploadTypes = [],
    frequency = [],
    currencies = [],
    utilityTypes = [],
    utilitySubtypesMap = {},
  } = allowedValues || {};

  const scope = [
    {
      id: "1",
      name: "Scope 1",
    },
    {
      id: "2",
      name: "Scope 2",
    },
    {
      id: "3",
      name: "NO_SCOPE",
    },
  ];

  useEffect(() => {
    if (values.utilityTypeName) {
      const selectedUtilityTypeInfo = utilityTypes.find(
        (ut: any) =>
          ut.name.toLowerCase() === values.utilityTypeName.toLowerCase(),
      );

      if (selectedUtilityTypeInfo) {
        setAvailableUnitDescriptions(selectedUtilityTypeInfo.unitDescription);
      }
    }
  }, [values.utilityTypeName, utilityTypes]);

  const handleEditNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 250) {
      if (!isCollectorEditNotes) {
        setValues({ ...values, notes: value });
      } else {
        setValues({ ...values, notes: value });
        setNotes(value);
      }
      setNotesError(false);
    } else {
      setNotesError(true);
    }
  };

  const handleAddCollector = async () => {
    const createCollectorsRequestData: any = {
      accountNumber: checkNullValue(values.accountNumber),
      allowedDocuments: Array.isArray(values.allowedDocuments)
        ? checkNullValue(values.allowedDocuments)
        : [values.allowedDocuments],
      consumptionUnit: checkNullValue(values.consumptionUnit),
      currency: checkNullValue(values.currency),
      startDate: checkNullValue(values.startDate),
      endDate: checkNullValue(values.endDate),
      frequency: checkNullValue(values.frequency),
      meterNumber: checkNullValue(values.meterNumber),
      providerName: checkNullValue(values.providerName),
      scope: checkNullValue(values.scope),
      isSpendBased: isSpendBasedEstimation,
      utilitySubType:
        values.utilityTypeName === "Refrigerants" ||
          values.utilityTypeName === "Diesel Vehicle" ||
          values.utilityTypeName === "Gasoline Vehicle" ||
          values.utilityTypeName === "Hybrid Vehicle"
          ? checkNullValue(values.utilitySubType)
          : null,

      siteId: site?.id || 0,
      utilityTypeId:
        utilityTypes.find(
          (ut: any) =>
            ut.name.toLowerCase() === values.utilityTypeName.toLowerCase(),
        )?.id || 0,
      notes: values.notes,
      ...(isSpendBasedEstimation ? { unitCost: 0 } : {}),
    };

    // Check if utility sub type is required and not provided
    if (
      (values.utilityTypeName === "Refrigerants" ||
        values.utilityTypeName === "Diesel Vehicle" ||
        values.utilityTypeName === "Gasoline Vehicle" ||
        values.utilityTypeName === "Hybrid Vehicle") &&
      !values.utilitySubType
    ) {
      notify.error("Utility Subtype is required.");
      return; // Stop further execution
    }

    // Proceed with creating collector if all checks pass
    await createCollectorRequest(createCollectorsRequestData, {
      onError: () => {
        notify.error(`Some error has happened while adding Collector!`);
      },
      onSuccess: () => {
        notify.success(`Collector successfully added!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        onClose();
        if (site && onCollectorDataUpdate) {
          getSiteById(site?.id)
            .then((siteData) => {
              onCollectorDataUpdate(siteData);
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error("Error fetching site data:", error);
            });
        }
      },
    });
  };

  const handleEditCollector = async () => {
    if (!isCollectorEditNotes) {
      const editCollectorsRequestData: any = {
        accountNumber: checkNullValue(values.accountNumber),
        allowedDocuments: Array.isArray(values.allowedDocuments)
          ? checkNullValue(values.allowedDocuments)
          : [values.allowedDocuments],
        consumptionUnit: checkNullValue(values.consumptionUnit),
        currency: checkNullValue(values.currency),
        frequency: checkNullValue(values.frequency),
        startDate: checkNullValue(values.startDate),
        endDate: checkNullValue(values.endDate),
        meterNumber: checkNullValue(values.meterNumber),
        providerName: checkNullValue(values.providerName),
        scope: checkNullValue(values.scope),
        unitCost: checkNullValue(values.unitCost),
        isSpendBased: isSpendBasedEstimation,
        notes: checkNullValue(values.notes),
        utilitySubType:
          values.utilityTypeName === "Refrigerants" ||
            values.utilityTypeName === "Diesel Vehicle" ||
            values.utilityTypeName === "Gasoline Vehicle" ||
            values.utilityTypeName === "Hybrid Vehicle"
            ? checkNullValue(values.utilitySubType)
            : null,
        siteId: site?.id || 0,
        id: editCollector?.id,
        utilityTypeId:
          utilityTypes.find(
            (ut: any) =>
              ut.name.toLowerCase() === values.utilityTypeName.toLowerCase(),
          )?.id || 0,
      };

      if (
        (values.utilityTypeName === "Refrigerants" ||
          values.utilityTypeName === "Diesel Vehicle" ||
          values.utilityTypeName === "Gasoline Vehicle" ||
          values.utilityTypeName === "Hybrid Vehicle") &&
        !values.utilitySubType
      ) {
        notify.error("Utility Subtype is required.");
        return;
      }
      await updateCollectorRequest(editCollectorsRequestData, {
        onError: () => {
          notify.error(`Some error has happened while editing Collector!`);
        },
        onSuccess: () => {
          notify.success(`Collector successfully edited!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          onClose();
          if (site && onCollectorDataUpdate) {
            getSiteById(site?.id)
              .then((siteData) => {
                onCollectorDataUpdate(siteData);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    } else {
      const notesData = {
        accountNumber: collectorData.accountNumber,
        allowedDocuments: Array.isArray(collectorData.allowedDocuments)
          ? collectorData.allowedDocuments
          : [collectorData.allowedDocuments],
        consumptionUnit: collectorData.consumptionUnit,
        currency: collectorData.currency,
        frequency: collectorData.frequency,
        meterNumber: collectorData.meterNumber,
        providerName: collectorData.providerName,
        notes: values.notes,
        scope: collectorData.scope,
        unitCost: collectorData.unitCost,
        isSpendBased: isSpendBasedEstimation,
        siteId: site?.id ?? 0,
        id: collectorData?.id,
        utilityTypeId: collectorData.utilityTypeId,
        utilitySubType: collectorData.utilitySubType,
      };
      await updateCollectorRequest(notesData, {
        onError: () => {
          notify.error(`Some error has happened while updating notes!`);
        },
        onSuccess: () => {
          notify.success(`Notes successfully Updated!`);
          onClose();
          if (site && onCollectorDataUpdate) {
            getSiteById(site?.id)
              .then((siteData) => {
                onCollectorDataUpdate(siteData);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    }
    if (site && onCollectorDataUpdate) {
      getSiteById(site?.id)
        .then((siteData) => {
          onCollectorDataUpdate(siteData);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error fetching site data:", error);
        });
    }
  };

  const customFieldsOrder = [
    "utilityTypeName",
    "utilitySubType",
    "accountNumber",
    "meterNumber",
    "providerName",
    "allowedDocuments",
    "startDate",
    "endDate",
    "frequency",
    "consumptionUnit",
    "currency",
    "scope",
    "unitCost",
    "notes",
  ];

  const sortedColumns = customFieldsOrder.map((key) =>
    columns.find((column: any) => column.accessorKey === key),
  );

  const { data: utilityMapping } = useGetAllowedValues();

  const utilityKey = Object.keys(utilityMapping?.utilitySubtypesMap as any);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        {isCollectorEditNotes
          ? "Edit Notes"
          : editCollector
            ? " Utility Provider"
            : " New Utility Provider"}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "24px !important" }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {sortedColumns &&
              (isCollectorEditNotes
                ? sortedColumns.map((column: any) => {
                  if (column.accessorKey === "notes") {
                    return (
                      <Box sx={{ ml: "25px", width: "550px" }}>
                        <InputFieldWrapper label="Notes">
                          <TextField
                            sx={{ width: "100%" }}
                            id={column.accessorKey}
                            name={column.accessorKey}
                            onChange={handleEditNotes}
                            value={
                              isCollectorEditNotes
                                ? notes
                                : values.notes || ""
                            }
                            error={notesError}
                            helperText={
                              notesError
                                ? "Notes length should not exceed 250 characters"
                                : ""
                            }
                            multiline
                            rows={3}
                          />
                        </InputFieldWrapper>
                      </Box>
                    );
                  }
                  return null;
                })
                : sortedColumns?.map((column: any) => (
                  <Grid item xs={8} sm={6} key={column.header}>
                    {column.accessorKey === "providerName" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="name"
                          control={control}
                          render={({ fieldState: { error } }) => (
                            <TextField
                              id={column.accessorKey}
                              size="small"
                              error={!!error}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={values[column.accessorKey] || ""}
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "accountNumber" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="name"
                          control={control}
                          render={({ fieldState: { error } }) => (
                            <TextField
                              id={column.accessorKey}
                              helperText={
                                notesError
                                  ? "Notes length should not exceed 200 characters"
                                  : ""
                              }
                              size="small"
                              error={!!error}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={values[column.accessorKey] || ""}
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "meterNumber" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="name"
                          control={control}
                          render={({ fieldState: { error } }) => (
                            <TextField
                              id={column.accessorKey}
                              size="small"
                              error={!!error}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={values[column.accessorKey] || ""}
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "allowedDocuments" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="type"
                          control={control}
                          render={() => (
                            <Select
                              labelId="type-simple-select-label"
                              id="type-simple-select"
                              name={column.accessorKey}
                              autoWidth
                              fullWidth
                              sx={{ height: 40 }}
                              value={values[column.accessorKey] || ""}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    minWidth: 260,
                                    maxWidth: 'auto',
                                    whiteSpace: 'normal',
                                    
                                  },
                                },
                              }}
                            >
                              {uploadTypes.map((option: any) => (
                                <MenuItem
                                  sx={{
                                    fontWeight:
                                      TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: "#90959E",
                                    "&:hover": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                    "&.Mui-selected": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                  }}
                                  key={option}
                                  value={option}
                                >
                                  {option}{" "}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "startDate" && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <InputFieldWrapper label={column.header}>
                          <DatePicker
                            slotProps={{
                              textField: {
                                size: "small",
                                sx: {
                                  width: "100%",
                                },
                              },
                            }}
                            value={
                              values[column.accessorKey]
                                ? dayjs(values[column.accessorKey]).utc()
                                : null
                            }
                            onChange={(date) =>
                              setValues({
                                ...values,
                                [column.accessorKey]: dayjs(date).format(
                                  "YYYY-MM-DDTHH:mm:ss[Z]",
                                ),
                              })
                            }
                            format={getDateFormatset()}
                          />
                        </InputFieldWrapper>
                      </LocalizationProvider>
                    )}
                    {column.accessorKey === "endDate" && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <InputFieldWrapper label={column.header}>
                          <DatePicker
                            slotProps={{
                              textField: {
                                size: "small",
                                sx: {
                                  width: "100%",
                                },
                              },
                            }}
                            value={
                              values[column.accessorKey]
                                ? dayjs(values[column.accessorKey]).utc()
                                : null
                            }
                            onChange={(date) =>
                              setValues({
                                ...values,
                                [column.accessorKey]: dayjs(date).format(
                                  "YYYY-MM-DDTHH:mm:ss[Z]",
                                ),
                              })
                            }
                            format={getDateFormatset()}
                          />
                        </InputFieldWrapper>
                      </LocalizationProvider>
                    )}
                    {column.accessorKey === "frequency" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="type"
                          control={control}
                          render={() => (
                            <Select
                              labelId="type-simple-select-label"
                              id="type-simple-select"
                              name={column.accessorKey}
                              autoWidth
                              fullWidth
                              sx={{ height: 40 }}
                              value={values[column.accessorKey] || ""}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    minWidth: 260,
                                    maxWidth: 'auto',
                                    whiteSpace: 'normal',
                                    
                                  },
                                },
                              }}
                            >
                              {frequency.map((option: any) => (
                                <MenuItem
                                  sx={{
                                    fontWeight:
                                      TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: "#90959E",
                                    "&:hover": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                    "&.Mui-selected": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                  }}
                                  key={option}
                                  value={option}
                                >
                                  {option}{" "}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "consumptionUnit" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="type"
                          control={control}
                          render={() => (
                            <Select
                              labelId="type-simple-select-label"
                              id="type-simple-select"
                              name={column.accessorKey}
                              autoWidth
                              fullWidth
                              sx={{ height: 40 }}
                              value={values[column.accessorKey] || ""}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    minWidth: 260,
                                    maxWidth: 'auto',
                                    whiteSpace: 'normal',
                                    
                                  },
                                },
                              }}
                            >
                              {availableUnitDescriptions.map(
                                (option: any) => (
                                  <MenuItem
                                    sx={{
                                      fontWeight:
                                        TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                      fontSize: 14,
                                      lineHeight: "18px",
                                      color: "#90959E",
                                      "&:hover": {
                                        backgroundColor: "#F6F8FA",
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                      "&.Mui-selected": {
                                        backgroundColor: "#F6F8FA",
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                    }}
                                    key={option}
                                    value={option.unit}
                                  >
                                    {`${option.unit} (${option.description})`}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "currency" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="type"
                          control={control}
                          render={() => (
                            <Select
                              labelId="type-simple-select-label"
                              id="type-simple-select"
                              name={column.accessorKey}
                              autoWidth
                              fullWidth
                              sx={{ height: 40 }}
                              value={values[column.accessorKey] || ""}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    minWidth: 260,
                                    maxWidth: 'auto',
                                    whiteSpace: 'normal',
                                    
                                  },
                                },
                              }}
                            >
                              {currencies.map((option: any) => (
                                <MenuItem
                                  sx={{
                                    fontWeight:
                                      TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: "#90959E",
                                    "&:hover": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                    "&.Mui-selected": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                  }}
                                  key={option}
                                  value={option}
                                >
                                  {option}{" "}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "utilityTypeName" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="type"
                          control={control}
                          render={() => (
                            <Select
                              labelId="type-simple-select-label"
                              id="type-simple-select"
                              name={column.accessorKey}
                              autoWidth
                              fullWidth
                              sx={{ height: 40 }}
                              value={values[column.accessorKey] || ""}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    minWidth: 260,
                                    maxWidth: 'auto',
                                    whiteSpace: 'normal',
                                    
                                  },
                                },
                              }}
                            >
                              {Object.values(utilityTypes).map(
                                (type: any) => (
                                  <MenuItem
                                    sx={{
                                      fontWeight:
                                        TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                      fontSize: 14,
                                      lineHeight: "18px",
                                      color: "#90959E",
                                      "&:hover": {
                                        backgroundColor: "#F6F8FA",
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                      "&.Mui-selected": {
                                        backgroundColor: "#F6F8FA",
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                    }}
                                    key={type.id}
                                    value={type.name}
                                  >
                                    {type.name}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "utilitySubType" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="type"
                          control={control}
                          render={() => {
                            const subtypes =
                              utilitySubtypesMap[values.utilityTypeName] ||
                              [];
                            return (
                              <Select
                                disabled={
                                  !utilityKey.some(
                                    (t) => t === values.utilityTypeName,
                                  )
                                }
                                labelId="type-simple-select-label"
                                id="type-simple-select"
                                name={column.accessorKey}
                                autoWidth
                                fullWidth
                                sx={{ height: 40 }}
                                value={values[column.accessorKey] || ""}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      minWidth: 260,
                                      maxWidth: 'auto',
                                      whiteSpace: 'normal',
                                      
                                    },
                                  },
                                }}
                              >
                                {subtypes.map((subtype: any) => (
                                  <MenuItem
                                    sx={{
                                      fontWeight:
                                        TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                      fontSize: 14,
                                      lineHeight: "18px",
                                      color: "#90959E",
                                      "&:hover": {
                                        backgroundColor: "#F6F8FA",
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                      "&.Mui-selected": {
                                        backgroundColor: "#F6F8FA",
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                    }}
                                    key={subtype}
                                    value={subtype}
                                  >
                                    {subtype}
                                  </MenuItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </InputFieldWrapper>
                    )}
                    {column.accessorKey === "scope" && (
                      <InputFieldWrapper label={column.header}>
                        <Controller
                          name="type"
                          control={control}
                          render={() => (
                            <Select
                              labelId="type-simple-select-label"
                              id="type-simple-select"
                              name={column.accessorKey}
                              autoWidth
                              fullWidth
                              sx={{ height: 40 }}
                              value={values[column.accessorKey] || ""}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    minWidth: 260,
                                    maxWidth: 'auto',
                                    whiteSpace: 'normal',
                                    
                                  },
                                },
                              }}
                            >
                              {scope.map((type: any) => (
                                <MenuItem
                                  sx={{
                                    fontWeight:
                                      TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: "#90959E",
                                    "&:hover": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                    "&.Mui-selected": {
                                      backgroundColor: "#F6F8FA",
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                  }}
                                  key={type.id}
                                  value={type.name}
                                >
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </InputFieldWrapper>
                    )}
                    {isSpendBasedEstimation &&
                      column.accessorKey === "unitCost" && (
                        <InputFieldWrapper label={column.header}>
                          <Controller
                            name="name"
                            control={control}
                            render={({ fieldState: { error } }) => (
                              <TextField
                                disabled
                                id={column.accessorKey}
                                size="small"
                                error={!!error}
                                name={column.accessorKey}
                                helperText={
                                  <FormHelperText
                                    sx={{
                                      color: "red",
                                      fontFamily: "Manrope",
                                      fontSize: "11px",
                                      whiteSpace: "pre-wrap",
                                      overflow: "hidden",
                                      width: "240px",

                                      "&.MuiFormHelperText-root": {
                                        margin: "0px",
                                        marginLeft: "-11px",
                                        color: "red",
                                      },
                                    }}
                                  >
                                    Define Unit Cost within the Utility
                                    Providers table to see historical Unit
                                    Cost for this country
                                  </FormHelperText>
                                }
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                value={
                                  editCollector &&
                                    editCollector.unitCost !== null
                                    ? editCollector.unitCost
                                    : `${values.currency}/${values.consumptionUnit}`
                                }
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </InputFieldWrapper>
                      )}
                  </Grid>
                )))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          mb={2}
          ml={2}
        >
          {editCollector && (
            <Grid item>
              <Switch
                isChecked={isSpendBasedEstimation}
                label="Spend Based Estimation"
                onChange={() =>
                  setIsSpendBasedEstimation(!isSpendBasedEstimation)
                }
              />
            </Grid>
          )}
          <Grid item sx={{ px: '24px' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() =>
                editCollector || isCollectorEditNotes
                  ? handleEditCollector()
                  : handleAddCollector()
              }
              variant="contained"
              color="primary"
            >
              {editCollector || isCollectorEditNotes ? "Save" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
